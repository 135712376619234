import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import ReCAPTCHA from "react-google-recaptcha"
import gql from "graphql-tag"
import { withFormik, Form, Field } from "formik"
import * as Yup from "yup" 
import { useMutation } from "@apollo/react-hooks"
import Select from "react-select"
import OtpInput from "react-otp-input"
import NumberFormat from "react-number-format"
import Modal from "react-modal"

import SEO from "../../components/seo"
import Layout from "../../components/layout"

import "../../graphql/fragments"

import { CoverMain } from "../../components/cover-main/cover-main"
import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"
import { HeadingBasic } from "../../components/heading/heading"
import { Container, Section } from "../../components/grid/grid"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const SUBMIT_FORM = gql`
  mutation MyMutation(
    $fullName: String!
    $phoneNumber: String!
    $message: String!
    $email: String!
  ) {
    submit2FormContactUs(
      input: {
        clientMutationId: ""
        fullName: $fullName
        phoneNumber: $phoneNumber
        email: $email
        message: $message
      }
    ) {
      status
      message
    }
  }
`

const GET_OTP = gql`
  mutation getOtp($phoneNumber: String) {
    requestOtp(input: { clientMutationId: "", phoneNumber: $phoneNumber }) {
      status
      message
    }
  }
`

const SUBMIT_OTP = gql`
  mutation SubmitFormInfo(
    $fullName: String
    $email: String
    $phoneNumber: String
    $otpCode: String
  ) {
    submit2FormGetMoreInfo(
      input: {
        clientMutationId: ""
        fullName: $fullName
        email: $email
        phoneNumber: $phoneNumber
        otpCode: $otpCode
      }
    ) {
      status
      message
    }
  }
`

const LeadForm = ({ errors, touched, values, setFieldValue, btnLoading }) => {
  const phoneNumberHandler = (val, setFieldValue) =>
    setFieldValue("phoneNumber", val)
  return (
    <Form className="row pt-4">
      <div className="col-12 col-md-4 pb-4 pb-md-0">
        <label className="text-muted my-0">Name</label>
        <Field
          name="name"
          className="form-control px-0"
          placeholder="Enter your name"
          type="text"
        />
        {touched.name && errors.name && (
          <div className="invalid-value d-block">{errors.name}</div>
        )}
      </div>
      <div className="col-12 col-md-4 pb-4 pb-md-0">
        <label className="text-muted my-0">Email</label>
        <Field
          name="email"
          className="form-control px-0"
          placeholder="Enter your email"
          type="text"
        />
        {touched.email && errors.email && (
          <div className="invalid-value d-block">{errors.email}</div>
        )}
      </div>
      <div className="col-12 col-md-4">
        <label className="text-muted my-0">Phone Number</label>
        <NumberFormat
          name="phoneNumber"
          className="form-control px-0"
          placeholder="Enter your phone number"
          allowEmptyFormatting
          prefix="+62"
          value={values.phoneNumber}
          onValueChange={val =>
            phoneNumberHandler(val.formattedValue, setFieldValue)
          }
        />
        {touched.phoneNumber && errors.phoneNumber && (
          <div className="invalid-value d-block">{errors.phoneNumber}</div>
        )}
      </div>
      <div className="col-12 pt-5">
        <button disabled={btnLoading} className="btn btn-primary w-100 w-md-25">
          {btnLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
        </button>
        <p className="pt-3 text-muted">
          Sinar Mas Land will never sell or share your information with a third
          party
        </p>
      </div>
    </Form>
  )
}

const ContactPage = ({ location }) => {
  const [formValue, setFormValue] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    subject: "",
    message: "",
    categories: "resident",
  })
  const [status, setStatus] = useState(false)
  const [otp, setOtp] = useState()
  const [successModal, setSuccessModal] = useState(false)
  const [otpError, setOtpError] = useState(false)
  const [timeLimit, setTimeLimit] = useState(30)
  const [showModal, setShowModal] = useState(false)

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  }

  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerCommercial.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wordPress {
        pageBy(uri: "contact-us") {
          slug
          title
          blocks {
            ...CoreCoverBlock
            ...CoreShortcodeBlock
          }
          translation(language: ID) {
            title
            blocks {
              ...CoreCoverBlock
              ...CoreShortcodeBlock
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
        }
        generalSettings {
          officeAddress
          officeFax
          officePhone
        }
      }
    }
  `)

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  })
  const [submitData, { loading }] = useMutation(SUBMIT_FORM)
  const [getOtp, { loading: getOtpLoading }] = useMutation(GET_OTP)
  const [submitOtp, { loading: submitOtpLoading }] = useMutation(SUBMIT_OTP)

  useEffect(() => {
    let timer = setTimeout(() => {
      if (timeLimit >= 0 && showModal) {
        setTimeLimit(timeLimit - 1)
      }
    }, 1000)
    return () => {
      clearTimeout(timer)
    }
  }, [timeLimit, showModal])

  const ContactForm = ({ errors, touched }) => {
    const button = submitStatus => {
      if (submitStatus === "success") {
        return (
          <>
            <button
              disabled
              className="btn btn-success btn-block mt-4"
              type="submit"
            >
              <i className="fas fa-check"></i>
            </button>
            <div className="d-flex justify-content-center pt-3">
              <HeadingBasic text={["Your message has been delivered"]} />
            </div>
          </>
        )
      } else if (submitStatus === "error") {
        return (
          <>
            <button
              disabled={loading}
              className="btn btn-primary btn-block mt-4"
              type="submit"
            >
              Send
            </button>
            <div className="d-flex justify-content-center pt-3">
              <HeadingBasic text={["Something went wrong"]} />
            </div>
          </>
        )
      } else {
        return (
          <button
            disabled={loading}
            className="btn btn-primary btn-block mt-4"
            type="submit"
          >
            {loading ? <i className="fa fa-spinner fa-spin"></i> : "Send"}
          </button>
        )
      }
    }
    return (
      <Form>
        <div className="row">
          <div className="col-12 mb-4">
            <label className="font-weight-bold">RECIPIENT</label>
            <Select
              className="form-select w-100"
              classNamePrefix="select"
              options={[
                { value: "sales", label: "Sales" },
                { value: "customer care", label: "Customer Care" },
                {
                  value: "others",
                  label: "Others",
                },
              ]}
              defaultValue={{ value: "sales", label: "Sales" }}
            />
          </div>
          <div className="col-12">
            <label className="font-weight-bold">CATEGORIES</label>
            <Field
              name="categories"
              render={({ field }) => {
                return (
                  <div className="d-flex mb-3 mb-lg-4">
                    <label className="radio-wrapper mr-4 mr-md-6">
                      Resident
                      <input
                        {...field}
                        id="contact-us"
                        type="radio"
                        checked={field.value === "resident"}
                        name="categories"
                        value="resident"
                      />
                      <span className="radio-button" />
                    </label>
                    <label className="radio-wrapper">
                      General Inquiry
                      <input
                        {...field}
                        id="contact-us"
                        type="radio"
                        checked={field.value === "general-inquiry"}
                        name="categories"
                        value="general-inquiry"
                      />
                      <span className="radio-button" />
                    </label>
                  </div>
                )
              }}
            />
          </div>
          <div className="col-lg-12 mb-3 mb-lg-4">
            <Field
              name="fullname"
              className="form-control px-0"
              placeholder={"Fullname"}
              type="text"
            />
            {touched.fullname && errors.fullname && (
              <div className="invalid-value d-block">{errors.fullname}</div>
            )}
          </div>
          <div className="col-lg-6 mb-3 mb-lg-4">
            <Field
              name="phonenumber"
              className="form-control px-0"
              placeholder={"Phone Number"}
              type="number"
            />
            {touched.phonenumber && errors.phonenumber && (
              <div className="invalid-value d-block">{errors.phonenumber}</div>
            )}
          </div>
          <div className="col-lg-6 mb-3 mb-lg-4">
            <Field
              name="email"
              className="form-control px-0"
              placeholder="Email"
              type="email"
            />
            {touched.email && errors.email && (
              <div className="invalid-value d-block">{errors.email}</div>
            )}
          </div>
          <div className="col-lg-12 mb-3 mb-lg-4">
            <Field
              name="subject"
              className="form-control px-0"
              placeholder={"Subject"}
              type="text"
            />
            {touched.subject && errors.subject && (
              <div className="invalid-value d-block">{errors.subject}</div>
            )}
          </div>
          <div className="col-12">
            <Field
              as="textarea"
              name="message"
              className="form-control px-0"
              placeholder={"Message"}
            ></Field>
            {touched.message && errors.message && (
              <div className="invalid-value d-block">{errors.message}</div>
            )}
          </div>
          <div className="col-12 my-4">
            <Field name="recaptcha">
              {({ field, form }) => (
                <ReCAPTCHA
                  sitekey={process.env.RECAPTCHA_SITE_KEY}
                  onChange={value => {
                    form.setFieldValue("recaptcha", value)
                  }}
                />
              )}
            </Field>
            {touched.recaptcha && errors.recaptcha && (
              <div className="invalid-value d-block">{errors.recaptcha}</div>
            )}
          </div>
        </div>
        {button(status)}
      </Form>
    )
  }

  const EnhancedLeadForm = withFormik({
    mapPropsToValues({ name, phoneNumber, email }) {
      return {
        name: formValues.name || name,
        email: formValues.email || email,
        phoneNumber: formValues.phoneNumber || phoneNumber,
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string().required("Email is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
    }),
    handleSubmit(values) {
      setFormValues(values)
      const formatNumber = values.phoneNumber.split("+").join("")
      getOtp({ variables: { phoneNumber: formatNumber } }).then(res => {
        console.log(res)
        if (res.data.requestOtp.status === "success") {
          setShowModal(true)
        }
      })
    },
  })(LeadForm)

  const FormApp = withFormik({
    mapPropsToValues({
      categories,
      fullname,
      phonenumber,
      email,
      subject,
      message,
      recaptcha,
    }) {
      return {
        fullname: fullname || formValue.fullName,
        phonenumber: phonenumber || formValue.phoneNumber,
        email: email || formValue.email,
        subject: subject || formValue.subject,
        message: message || formValue.message,
        categories: categories || formValue.categories,
        recaptcha: recaptcha || formValue.recaptcha,
      }
    },
    validationSchema: Yup.object().shape({
      fullname: Yup.string().required("Required"),
      phonenumber: Yup.number().required("Required"),
      email: Yup.string().required("Required"),
      subject: Yup.string().required("Required"),
      message: Yup.string().required("Required"),
      recaptcha: Yup.string().required(
        "Please verify that you are not a robot"
      ),
    }),
    handleSubmit(values) {
      setFormValue({
        fullName: values.fullname,
        phoneNumber: values.phonenumber,
        email: values.email,
        subject: values.subject,
        message: values.message,
      })

      setTimeout(() => {
        submitData({
          variables: {
            fullName: values.fullname,
            phoneNumber: values.phonenumber,
            email: values.email,
            subject: values.subject,
            message: values.message,
          },
        })
          .then(res => {
            if (res.data.submit2FormContactUs.status === "success") {
              setStatus("success")

              // window.dataLayer = window.dataLayer || []
              // dataLayer.push({
              //   event: "contactUs",
              //   event_category: "Form Submit",
              //   event_action: "Form Submit Contant Us",
              //   event_label: "Form Submit Success",
              // })
            }
          })
          .catch(err => {
            console.log(err)
            setStatus("error")
          })
      }, 500)
    },
  })(ContactForm)

  const wordPress = data?.wordPress?.pageBy?.blocks

  const seo = data.wordPress.pageBy.seo
  const cover =
    wordPress &&
    wordPress.find(x => x.name === "core/cover") === wordPress[0] &&
    wordPress[0]

  const handleSuccessModal = () => setSuccessModal(false)
  const handleOtp = otp => {
    setOtp(otp)
  }
  const getOtpHandler = () =>
    getOtp({
      variables: { phoneNumber: formValues.phoneNumber.split("+").join("") },
    }).then(res => {
      console.log(res)
      if (res.data.requestOtp.status === "success") {
        setTimeLimit(30)
        setShowModal(true)
      }
    })

  const sendOtp = () => {
    const formatNumber = formValues.phoneNumber.split("+").join("")

    submitOtp({
      variables: {
        fullName: formValues.name,
        email: formValues.email,
        phoneNumber: formatNumber,
        otpCode: otp,
      },
    }).then(res => {
      if (res.data.submit2FormGetMoreInfo.status === "success") {
        setShowModal(false)
        setSuccessModal(true)
        setTimeLimit(30)
        setFormValues({ fullName: "", email: "", phoneNumber: "" })
        setOtp("")
        if (otpError) {
          setOtpError(false)
        }
      } else {
        setOtpError(true)
      }
    })
  }

  return (
    <Layout location={location} lang="EN">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle}
        twitterTitle={seo?.twitterTitle}
        twitterDescription={seo?.twitterDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        twitterImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        img={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      {cover && (
        <CoverMain
          h1={data?.wordPress?.pageBy?.title}
          img={
            cover
              ? cover.attributes.url
              : data.imgCoverMain.childImageSharp.fluid
          }
          alt="Social"
          className="cover-md"
        >
          <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
        </CoverMain>
      )}

      {wordPress?.map(item => {
        switch (item.name) {
          case "core/shortcode":
            if (item.attributes.text === "contact-us-form") {
              return (
                <section className="bg-gray-50 pb-main">
                  <div className="container mw-lg px-sm-down-0 my-4 my-md-0">
                    <div className="contact-wrapper">
                      <div className="row row-0">
                        <div className="col-md-7">
                          <div className="contact-message bg-white">
                            <h2 className="h2 mb-4">Send Us a Message</h2>
                            <FormApp />
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="contact-info bg-gray-20 h-100 pt-5">
                            <h2 className="h2">Reach Us</h2>
                            <h3 className="h5">Head Office</h3>
                            <p>
                              {data.wordPress.generalSettings.officeAddress}
                            </p>
                            <p>
                              <i className="fas fa-phone">&nbsp;</i><a href={`tel:${data.wordPress.generalSettings.officePhone}`}>{data.wordPress.generalSettings.officePhone}</a>
                              <br />
                              <i className="fas fa-envelope-square">&nbsp;</i>
                              <a href="mailto:info@sinarmasland.com">
                                info@sinarmasland.com
                              </a>
                            </p>
                            <i className="fal fa-map-marker-alt">&nbsp;</i>
                            <a
                              href="https://goo.gl/maps/FS2SCAjURCp8ogQ38"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="btn btn-link fx-underline"
                            >
                              Get Direction
                            </a>
                            <h3 className="h5 mt-5">Singapore Office</h3>
                            <p>
                              108 Jalan Pasir Panjang Road #06-00 Golden Agri
                              Plaza Singapore 118535
                            </p>
                            <i className="fal fa-map-marker-alt">&nbsp;</i>
                            <a
                              href="https://goo.gl/maps/2aVe2EhXHpjKRTxo6"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="btn btn-link fx-underline"
                            >
                              Get Direction
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )
            } else if (item.attributes.text === "lead-form") {
              return (
                <Section className="sc-lead-form">
                  <Container>
                    <h2>Get More Information</h2>
                    <p className="text-muted">
                      Find out more about this project
                    </p>
                    <EnhancedLeadForm btnLoading={getOtpLoading} />
                  </Container>
                </Section>
              )
            }
            break
        }
      })}

      <Modal
        isOpen={showModal}
        style={customStyles}
        contentLabel="campaign modal"
        overlayClassName="modal-overlay-general"
      >
        <div className="modal-popup-otp modal-body text-center">
          <div className="container py-5 mw-md-75 mw-100">
            <h2>OTP</h2>
            <p className="m-0">
              To proceed, please enter the 6-digits OTP code sent to your mobile
              number
            </p>
            <p className="font-weight-bold">{formValues.phoneNumber}</p>
            <OtpInput
              value={otp}
              containerStyle="otp-container"
              inputStyle={`otp-input ${otpError ? "error" : ""}`}
              onChange={handleOtp}
              numInputs={6}
              isInputNum
            />
            {otpError && (
              <p className="otp-error mt-3">
                The code you entered does not match, please re-enter.
              </p>
            )}
            {getOtpLoading ? (
              <p className="mt-md-5 mt-3 text-primary">
                <i className="fa fa-spinner fa-spin"></i>
              </p>
            ) : timeLimit > 0 ? (
              <p className="mt-md-5 mt-3 text-muted">
                Haven’t received OTP code? Resend ({timeLimit})
              </p>
            ) : (
              <button
                className="btn mt-md-5 mt-3 text-primary"
                onClick={getOtpHandler}
              >
                Haven’t received OTP code? <strong>Resend</strong>
              </button>
            )}
            <button
              disabled={submitOtpLoading}
              className="btn btn-primary w-50 mt-3"
              onClick={sendOtp}
            >
              {submitOtpLoading ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={successModal}
        onRequestClose={handleSuccessModal}
        style={customStyles}
        contentLabel="campaign modal"
        overlayClassName="modal-overlay-general"
      >
        <div className="modal-popup-otp modal-body text-center">
          <div className="container py-5 mw-100 mw-md-75">
            {/* <img alt="success" src={icSuccess} className="mw-25 mb-4" /> */}
            <h2>OTP Verified!</h2>
            <p className="m-0">
              Your data has been submitted. Our Sinar Mas Land team will reach
              out to you.
            </p>
            <button
              className="btn btn-primary w-50 mt-4"
              onClick={handleSuccessModal}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  )
}

export default ContactPage
